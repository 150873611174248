import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers([
  'root',
  'heading',
  'icon',
  'name',
])

export const ItemRecordCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    root: {
      p: '1rem',
      cursor: 'default',
    },
    heading: {
      flexDir: 'row',
      alignItems: 'start',
      justifyContent: 'space-between',
      maxWidth: '100%',
      cursor: 'default',
      wordBreak: 'normal',
    },
    icon: {
      cursor: 'pointer',
      color: 'interaction.sub.default',
      boxSize: '1.25rem',
    },
    name: {
      textStyle: 'subhead-1',
    },
  },
  variants: {
    disabled: {
      icon: {
        color: 'interaction.support.disabled-content',
        cursor: 'not-allowed',
      },
    },
  },
})
