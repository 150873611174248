import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const base = {
  borderRadius: '0.25rem',
  textStyle: 'subhead-1',
  px: '1rem',
  py: '0.625rem',
}
const destructive = defineStyle({
  ...base,
  _hover: {
    bgColor: 'interaction.critical.active',
  },
  bgColor: 'interaction.critical.default',
  borderColor: 'interaction.critical.default',
})

const secondary = defineStyle({
  ...base,
  _hover: {
    bgColor: 'interaction.neutral-subtle.hover',
  },
  bgColor: 'interaction.neutral-subtle.default',
  border: 'none',
  color: 'base.content.strong',
})

const interaction = defineStyle({
  ...base,
  borderColor: 'interaction.main.default',
  textColor: 'standard.white',
})

const destructiveOutlined = defineStyle({
  ...base,
  color: 'interaction.critical.default',
  bg: 'none',
  borderColor: 'interaction.critical.default',
  _hover: {
    bgColor: 'interaction.muted.critical.active',
  },
  _disabled: {
    bg: 'none',
    color: 'interaction.support.placeholder',
    borderColor: 'interaction.support.placeholder',
    cursor: 'not-allowed',
  },
})

export const ActionButton = defineStyleConfig({
  variants: { destructive, interaction, destructiveOutlined, secondary },
})
