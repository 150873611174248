import { Record } from '@prisma/client'
import {
  isPhotoRecord,
  isQuantityRecord,
  isSingleOptionSelectRecord,
} from '../schemas/questions/utils'
import { Operator } from '~/metadata'

/**
 * @param metadata The condition to evaluate based on the child item
 * @param parentRecord The parent record which the condition is evaluated against, to determine if it's true or false
 * @returns whether the record should be displayed, based on whether the condition evaluated against the parent is true or false
 *
 */
export const evaluateCondition = (
  metadata: PrismaJson.ConditionMetadata,
  parentRecord: Record,
) => {
  if (!metadata.cutoff) return false // do not show there is no condition provided
  if (metadata.cutoff.parentItemType !== parentRecord.metadata.question.type) {
    return false // the parent question type is not assignable to the condition specified, so do not show the record
  }
  switch (metadata.cutoff.parentItemType) {
    case 'SINGLE_OPTION_SELECT':
      if (!isSingleOptionSelectRecord(parentRecord))
        throw new Error('Expected the parent record to be a binary record')
      switch (metadata.cutoff.operator) {
        case Operator.EQUAL:
          return (
            parentRecord.metadata.question.actual ===
            metadata.cutoff.conditionCutoff
          )
        default:
          const unhandledCase: never = metadata.cutoff.operator
          if (unhandledCase)
            throw new Error(
              `Operator type ${metadata.cutoff.operator} has not been handled`,
            )
      }
      break
    case 'PHOTO':
      if (!isPhotoRecord(parentRecord))
        throw new Error('Expected the parent record to be a photo record')
      switch (metadata.cutoff.operator) {
        case Operator.EQUAL:
          return (
            parentRecord.metadata.question.actual ===
            metadata.cutoff.conditionCutoff
          )
        default:
          const unhandledCase: never = metadata.cutoff.operator
          if (unhandledCase)
            throw new Error(
              `Operator type ${metadata.cutoff.operator} has not been handled`,
            )
      }
      break

    case 'QUANTITY':
      if (!isQuantityRecord(parentRecord))
        throw new Error('Expected the parent record to be a binary record')
      switch (metadata.cutoff.operator) {
        case Operator.EQUAL:
          return (
            parentRecord.metadata.question.actual ===
            metadata.cutoff.conditionCutoff
          )
        default:
          const unhandledCase: never = metadata.cutoff.operator
          if (unhandledCase)
            throw new Error(
              `Operator type ${metadata.cutoff.operator} has not been handled`,
            )
      }
      break
  }
}
