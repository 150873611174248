import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['root'])

export const BaseCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    root: {
      flexDirection: 'column',
      position: 'relative',
      bgColor: 'standard.white',
      p: '1rem',
      m: 0,
      borderWidth: '1px',
      borderRadius: '0.5rem',
      borderColor: 'base.divider.medium',
      _active: { bgColor: 'base.canvas.brand-subtle' },
      cursor: 'pointer',
    },
  },
  variants: {
    itemset: {
      root: {
        borderRadius: 0,
      },
    },
    expanded: {
      root: {
        bgColor: 'brand.primary.100',
        borderRadius: 0,
      },
    },
    'checklist-card': {
      root: {
        borderWidth: '1px',
        _active: { bgColor: 'none', cursor: 'default' },
        cursor: 'default',
      },
    },
  },
})
