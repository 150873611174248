import { defineStyleConfig } from '@chakra-ui/react'

export const SubmissionContainer = defineStyleConfig({
  baseStyle: {
    root: {
      flexDir: 'column',
      position: 'relative',
      flexGrow: 1,
      backgroundColor: 'base.canvas.brand-subtle',
    },
    heading: {
      flexDir: 'column',
      py: '1.5rem',
      px: '1rem',
      color: 'base.content.default',
    },
    content: {
      // needs to be sufficiently tall to give space for the menu list
      // as menu list position is only calculated (to be at the top) when displayed
      pb: '4.5rem',
    },
    sectionHeading: {
      justifyContent: 'space-between',
    },
    sectionStatus: {
      my: 'auto',
      boxSize: '1.5rem',
    },
    sectionName: {
      textStyle: 'subhead-1',
    },
    checkCount: {
      textStyle: 'body-2',
      color: 'base.content.default',
    },
    itemset: {
      w: '100%',
      pl: '0.5rem',
    },
    backButton: {
      flex: 1,
    },
    iconContainer: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    alert: {
      textStyle: 'subhead-1',
      position: 'fixed',
      w: 'calc(100vw - 1rem)',
      top: '85vh',
      left: '0.5rem',
      py: '1.25rem',
    },
    alertLink: {
      color: 'blue.200',
      textDecoration: 'none',
    },
    closeButton: {
      position: 'absolute',
      right: '1rem',
      color: 'base.content.inverse',
    },
    submitButton: {
      alignItems: 'center',
      borderRadius: '0.25rem 0 0 0.25rem',
      h: '100%',
      color: 'standard.white',
      flexGrow: '1',
      justifyContent: 'center',
    },
    submitButtonContainer: {
      justifyContent: 'center',
      position: 'relative',
      h: '3.5rem',
      mb: '2rem',
    },
    menuContainer: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuItem: {
      bgColor: 'slate.800',
      color: 'standard.white',
      _active: { backgroundColor: 'brand.secondary.600' },
    },
    divider: {
      color: 'standard.white',
      h: '100%',
    },
    headerText: {
      textStyle: 'h3-semibold',
      color: 'base.content.default',
    },
    headingWithCountBox: {},
  },
  variants: {
    submissionViewRoot: {
      headerText: {
        color: 'standard.white',
      },
      heading: {
        bgColor: 'slate.900',
      },
    },
  },
})
